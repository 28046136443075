function getDomain() {
  return (
    process.env.NEXT_PUBLIC_DOMAIN ||
    process.env.NEXT_PUBLIC_VERCEL_URL ||
    process.env.VERCEL_URL
  )
}

export default getDomain

export function getOrigin() {
  const domain = <string>getDomain()
  const protocol = domain.includes('localhost') ? 'http' : 'https'
  return `${protocol}://${domain}`
}
