import { createPortal } from 'react-dom'
import classnames from 'classnames/bind'
import styles from './backdrop.module.scss'
import { useEffect, useState, useSyncExternalStore } from 'react'

const cx = classnames.bind(styles)

const Backdrop = ({ active }) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return mounted
    ? createPortal(
        <div className={cx('backdrop', { active })} />,
        document.body
      )
    : null
}

export default Backdrop
