import styles from './buttons.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const HamburgerBtn = (props) => {
  const { active } = props
  return (
    <button className={cx('hamburger-btn', { active })} onClick={props.onClick}>
      <span className={styles['inner']}>
        <span className={cx('line', 'first')} />
        <span className={cx('line', 'middle')} />
        <span className={cx('line', 'last')} />
      </span>
    </button>
  )
}

export const SubNavBtn = (props) => {
  const { active } = props
  return (
    <button className={cx('subnav-btn', { active })} onClick={props.onClick}>
      <span className={styles['inner']}>
        <span className={cx('line', 'vertical')} />
        <span className={cx('line', 'horizontal')} />
      </span>
    </button>
  )
}
