import Head from 'next/head'
import Meta, { MetaProps } from './meta'
// import Menu from '../menu'
import Header from 'components/header'
import Footer from '../footer'
import styles from './layout.module.scss'

type Props = {
  meta: MetaProps
  showFooter?: boolean
  footerContent?: any
  footerBorderless?: boolean
  title: string
  style?: object
  logo?: any
  active?: string
}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  meta = {},
  showFooter = true,
  footerContent,
  footerBorderless = false,
  title = 'Analogue',
  style = {},
  active = null,
  ...props
}) => {
  const pageTitle = title.includes('Analogue') ? title : `${title} - Analogue`
  const metaProps = { title, ...meta }

  return (
    <div id="Layout">
      <Head>
        <title>{pageTitle}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link
          rel="alternate icon"
          type="image/png"
          href="/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="RSS Feed for analogue.co"
          href="/feed/all"
        />
      </Head>
      <Meta {...metaProps} />
      {/* <Menu logo={props.logo} /> */}
      <Header active={active} />
      <main className={styles['wrapper']} style={style}>
        {props.children}
      </main>
      {showFooter ? <Footer borderless={footerBorderless} /> : null}
    </div>
  )
}

export default Layout
