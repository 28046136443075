import { useRef, useState } from 'react'
import cx from 'classnames'
import Modal from 'react-modal'
import { Spinner } from '../icon'
import Text from 'components/type/new'
import Type from 'components/type/v3'
import styles from './email.module.scss'
import sleep from 'helpers/sleep'
import { usePlausible } from 'next-plausible'

Modal.setAppElement('#__next')

export default function EmailSignup({ source, open = false, onModalClose }) {
  const emailRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [error, setError] = useState(false)
  const plausible = usePlausible()

  const onSubmit = async function (evt) {
    evt.preventDefault()
    const email = emailRef.current.value
    try {
      setLoading(true)
      setError(false)
      const body = JSON.stringify({
        email,
        signup_source: source,
      })
      const res = await fetch('/subscribe', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body,
      })
      if (!res.ok) {
        throw new Error(`${res.status}: ${res.statusText}`)
      }
      setSubscribed(true)
      setLoading(false)
      plausible('Newsletter Subscription', {
        props: {
          source,
        },
      })
      await sleep(1500)
      onModalClose()
    } catch (err) {
      console.error(err)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const classNames = cx(styles.form, { [styles['loading-state']]: loading })
  const subscribedLabel = subscribed ? 'Subscribed' : 'Subscribe'

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => onModalClose()}
      closeTimeoutMS={350}
    >
      <Text size="m" el="p" style={{ marginBottom: 'var(--space-2x)' }}>
        Enter your email to be added to our newsletter.
      </Text>
      <form
        className={classNames}
        id="Email-Form"
        action="/subscribe"
        method="POST"
        onSubmit={onSubmit}
      >
        <input
          type="email"
          ref={emailRef}
          className={styles.email}
          name="email"
          placeholder="Email Address"
          aria-label="Email Address"
          disabled={subscribed}
          required
        />
        <div className={styles['submit-container']}>
          <input
            type="submit"
            className={styles.submit}
            value={subscribedLabel}
            disabled={subscribed}
          />
          <div className={styles['loading-container']}>
            <div className={styles['loading-icon']}>
              <Spinner containerStyle={{ width: '20px', height: '20px' }} />
            </div>
          </div>
        </div>
      </form>
      {error && (
        <Type
          size="s"
          el="p"
          style={{ color: '#e04646', marginTop: 'var(--space-2x)' }}
          id="Email-Form-Error"
        >
          <strong>An error occurred.</strong> Please try again later.
        </Type>
      )}
    </Modal>
  )
}
