import classnames from 'classnames/bind'
import styles from './container.module.scss'

const cx = classnames.bind(styles)

type Props = {
  size?: 's' | 'm' | 'l' | 'support' | 'full' | 'new'
  width?: number
  className?: string
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type ContainerProps = NativeAttrs & Props

const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  size = 'l',
  width,
  className = null,
  ...props
}) => {
  const attr = {
    className: cx('container', `size_${size}`, className),
  }
  if (width) {
    attr['style'] = { maxWidth: width }
  }
  if (props.style) {
    attr['style'] = { ...attr['style'], ...props.style }
  }
  return <div {...attr}>{props.children}</div>
}

export default Container
