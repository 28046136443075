import { useState } from 'react'
import Link from 'next/link'
import { Instagram, Twitter } from '../icon'
import EmailSignup from './email'
import Text from 'components/type/new'
import styles from './footer.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

import Container from 'components/container'

const SocialLink = ({ href, name, children }) => (
  <a
    href={href}
    className={styles.social_link}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={name}
  >
    {children}
  </a>
)

const MenuLink = ({ href, children }) => {
  const internalHref = href[0] === '/'
  const isMail = href.includes('mailto:')
  if (internalHref) {
    return (
      <Link href={href}>
        <Text size="m" el="span">
          {children}
        </Text>
      </Link>
    )
  } else if (isMail) {
    return (
      <a href={href} className={styles.footer_link} rel="noopener noreferrer">
        <Text size="m" el="span">
          {children}
        </Text>
      </a>
    )
  }
  return (
    <a
      href={href}
      className={styles.footer_link}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Text size="m" el="span">
        {children}
      </Text>
    </a>
  )
}

const LINKS = [
  {
    href: '/press-kit',
    label: 'Press',
  },
  {
    href: '/feed',
    label: 'RSS',
  },
  { href: '/terms-conditions', label: 'Terms & Conditions' },
  { href: '/privacy-policy', label: 'Privacy' },
]

const Footer = ({ borderless = false }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const currentYear = new Date().getFullYear()
  return (
    <footer className={cx('container', { borderless })}>
      <Container size="l">
        <div className={styles['grid']}>
          <div className={styles['grid_copy']}>
            <Text size="m" el="span">
              &copy;{currentYear} Analogue
            </Text>
          </div>
          <div className={styles['grid_menu']}>
            <ul>
              <li>
                <button
                  className={styles['newsletter_btn']}
                  id="newsletter-footer-btn"
                  onClick={() => setModalOpen(true)}
                >
                  <Text el="span" size="m">
                    Newsletter
                  </Text>
                </button>
              </li>
              {LINKS.map(({ href, label }) => (
                <li key={href}>
                  <MenuLink href={href}>{label}</MenuLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles['grid_social']}>
            <SocialLink
              href="https://instagram.com/analogue.co"
              name="Instagram"
            >
              <Instagram />
            </SocialLink>
            <SocialLink href="https://twitter.com/analogue" name="Twitter">
              <Twitter />
            </SocialLink>
          </div>
        </div>
      </Container>
      <EmailSignup
        source="footer"
        open={modalOpen}
        onModalClose={() => setModalOpen(false)}
      />
    </footer>
  )
}

export default Footer
