import { useEffect, useRef } from 'react'
import Container from 'components/container'
import Link from 'next/link'
import Ark from 'components/logo/ark'
import Grid from 'components/grid'
import { useRouter } from 'next/router'
import useMenu from 'hooks/useMenu'
import styles from './header.module.scss'
import classNames from 'classnames/bind'
import Backdrop from './backdrop'
import { HamburgerBtn, SubNavBtn } from './buttons'

const cx = classNames.bind(styles)

const ITEMS = [
  {
    url: 'https://store.analogue.co',
    title: 'Store',
  },
  {
    url: '/products',
    title: 'Products',
  },
  {
    url: '/editions',
    title: 'Editions',
  },
  {
    url: '/os',
    title: 'OS',
  },
  {
    url: '/announcements',
    title: 'Announcements',
  },
  {
    url: '/support',
    title: 'Support',
  },
  {
    url: '/developer',
    title: 'Developer',
  },
]

export type MenuItem = {
  url: string
  title: string
  items?: MenuItem[]
}

export type MenuProps = {
  items: MenuItem[]
  id: string
  type: 'subnav' | 'primary'
  active?: string
}

const isActiveMenuItem = (
  path: string,
  item: MenuItem,
  position: number,
  activeOverride: string
) => {
  if (item.url === activeOverride) {
    return true
  }

  return position === 0 ? path === item.url : path.startsWith(item.url)
}

export const Menu = ({
  items = [],
  id,
  type = 'subnav',
  active,
}: MenuProps) => {
  const router = useRouter()
  const currentPath = router?.asPath
  const { open: menuOpen, setMenuOpen } = useMenu(id)
  const isPrimary = type === 'primary'

  return (
    <Grid.Item className={styles['menu-wrapper']}>
      <menu className={cx('menu', { active: menuOpen, [type]: type })}>
        <ul>
          {items.map((item, index) => {
            const isActive = isActiveMenuItem(currentPath, item, index, active)
            return (
              <HeaderItem
                key={item.url}
                {...item}
                active={isActive}
                primary={isPrimary}
              />
            )
          })}
        </ul>
        {isPrimary && <Backdrop active={menuOpen} />}
      </menu>
      <div className={styles['nav-btn']}>
        {type === 'primary' ? (
          <HamburgerBtn
            active={menuOpen}
            onClick={(evt) => {
              evt.preventDefault()
              setMenuOpen(!menuOpen)
            }}
          />
        ) : (
          <SubNavBtn
            active={menuOpen}
            onClick={(evt) => {
              evt.preventDefault()
              setMenuOpen(!menuOpen)
            }}
          />
        )}
      </div>
    </Grid.Item>
  )
}

export const HeaderTitle = ({ children, stuck = false }) => {
  return (
    <Grid.Item className={styles['logo-wrapper']}>
      <div className={cx('header-title', { stuck })}>{children}</div>
    </Grid.Item>
  )
}

export const HeaderLogo = () => (
  <Grid.Item className={styles['logo-wrapper']}>
    <div className={styles['logo']}>
      <Link href="/">
        <Ark />
      </Link>
    </div>
  </Grid.Item>
)

const HeaderItem = ({
  active = false,
  items = [],
  title,
  url,
  primary = false,
}) => {
  const hasNestedNav = items.length > 0
  const router = useRouter()
  const currentPath = router?.asPath
  const type = primary ? 'primary' : 'secondary'

  return (
    <li className={cx('item', `item-${type}`)}>
      <Link href={url} className={cx({ active })}>
        {title}
      </Link>
      {hasNestedNav && (
        <ul className={styles['nested-list']}>
          {items.map((item) => {
            const url = `/developer/docs/${item.slug}`
            const isActive = currentPath.startsWith(url)
            return (
              <li className={cx('item')} key={url}>
                <Link href={url} className={cx({ active: isActive })}>
                  {item.title}
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}

const Header = ({ active = null }) => {
  return (
    <div className={styles['container']}>
      <Container size="l" style={{ width: '100%', height: '100%' }}>
        <Grid>
          <HeaderLogo />
          <Menu items={ITEMS} id="main-menu" type="primary" active={active} />
        </Grid>
      </Container>
    </div>
  )
}

export default Header
