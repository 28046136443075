import Head from 'next/head'
import { useRouter } from 'next/router'
import { getOrigin } from 'helpers/domain'

const DEFAULT_IMG =
  'https://images.analogue.co/social.jpg?w=1000&q=100&s=5d58c3ddd6c7e4de8973571db72dcfa5'

export interface MetaProps {
  title?: string
  description?: string
  url?: string
  image?: string
}

export default function HeadMeta({
  title = '',
  description = '',
  url,
  image = DEFAULT_IMG,
}: MetaProps) {
  const baseUrl = getOrigin()
  const imgUrl = `${baseUrl}/api/social/${encodeURIComponent(image)}`

  const router = useRouter()
  const pathname = url || router.asPath
  const pageUrl = `${baseUrl}${pathname}`

  return (
    <div>
      <Head>
        <meta name="title" content={title} />
        <meta name="og:title" content={title} />
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="og:url" content={pageUrl} />
        <meta property="twitter:url" content={pageUrl} />
        <meta property="og:image" content={imgUrl} />
        <meta property="twitter:image" content={imgUrl} />
        <meta property="twitter:site" content="@analogue" />
        <meta property="twitter:creator" content="@analogue" />
      </Head>
    </div>
  )
}
