import React from 'react'
import cx from 'classnames'
import styles from './new.module.scss'

type Props = {
  el?: keyof JSX.IntrinsicElements
  size?: 'sc' | 's' | 'm' | 'l' | 'career-l' | 'xl' | 'xxl'
  family?: 'default' | 'mono'
  className?: string
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type TextProps = NativeAttrs & Props

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  el = 'p',
  size = 'm',
  children,
  family = 'default',
  className = '',
  ...props
}) => {
  const El = el
  let classes = cx(styles['text'], styles[`size_${size}`], {
    [styles['mono']]: family === 'mono',
  })
  if (className) {
    classes += ` ${className}`
  }
  return (
    <El className={classes} {...props}>
      {children}
    </El>
  )
}

export default Text
