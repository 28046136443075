import ClassNames from 'classnames/bind'
import styles from './grid.module.scss'

const cx = ClassNames.bind(styles)

const Grid = ({ className = '', ...props }) => {
  return <div className={cx('grid', className)}>{props.children}</div>
}

export type GridItemProps = {
  size?: number
  className: string
  children: React.ReactNode
}

export const GridItem = ({ className = '', size, ...props }: GridItemProps) => {
  const style = {}
  if (size) {
    style['--size'] = size
  }
  const classNames = cx('grid-item', 'column', className)
  return (
    <div className={classNames} style={style as React.CSSProperties}>
      {props.children}
    </div>
  )
}

Grid.Item = GridItem

export default Grid
